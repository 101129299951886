.jhu-title {
    text-align: left;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.1;
    /*text-transform: uppercase;*/
    margin-bottom: 10px;
    margin-top: 20px;
}

.umd-title {
    text-align: left;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.1;
    /*text-transform: uppercase;*/
    margin-bottom: 10px;
}

.edu-text {
    text-align: left;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.3;
    /*text-transform: uppercase;*/
    margin-bottom: 10px;
}

.edu-image {
    height: auto;
    width: auto;
    max-width: 500px;
    max-height: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /*height: 57px;*/
    /*width: 350px;*/
}

/*To get image centered needed to wrap Image in div*/
/*div {*/
/*    text-align: center;*/
/*}*/