
/*.modal-body {*/
/*    background-color: #AAA0A0;*/
/*}*/
.modal-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.1;
  /*text-transform: uppercase;*/
  margin-bottom: 10px;

}
.modal-text {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.3;
  /*text-transform: uppercase;*/
    margin-top: 50px;
  margin-bottom: 50px;
}

.modal-image {
    height: auto;
    width: auto;
    max-width: 500px;
    max-height: 500px;
}
