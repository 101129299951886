html, body {
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', 'Helvetica';
}


.base-page {
    padding: 150px 0 100px 0;
    color: black;
    background-color: #844D36;
    /*min-height: 100vh;*/
}

 .page-header {

  }

.page-header-title {
      font-weight: 700;
      color: #AAA0A0;
      display: inline-block;
      margin-bottom: 20px;
    }