.footer {
    background-color: #844D36;
}

.footer-copyright {
    font-size: 23px;
    justify-content: center;
    text-align: center!important;
    margin-top: 1rem;
}

.footer-image {
    height: 50px;
    width: 50px;
    /*max-width: 50px;*/
    /*max-height: 50px;*/
 }

