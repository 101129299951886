.portfolio-page {
  min-height: 100vh;
  padding: 150px 0 0;
  background-color: #00aeef;
}
.portfolio-card {
  /*background-image: linear-gradient(45deg, #4e54c8 0%, #8f94fb 100%);*/
  /*border: none;*/
  /*color: white;*/
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  margin-bottom: 20px;
  box-shadow: 0 1px 20px 0 rgba(0,0,0,.75);
  background-color: #AAA0A0;
}

.portfolio-card-body {
  background-color: #AAA0A0;
}
.portfolio-card:hover {
  cursor: pointer;
  transform: translateY(-5px);
}
.portfolio-card:hover .readMore:before {
  background: linear-gradient(to right, #ffffff 0%, #ffffff 100%);
}
.portfolio-card-header {
  padding: 3px;
  padding-right: 7px;
  text-align: right;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 17px;
}

.header-python {
  background-color: #8E8268 !important;
}

.header-flutter {
  background-color: #86B3D1 !important;
}

.header-machine {
  background-color: #84A98C !important;
}

.header-react {
  background-color: #D8E15F !important;
}

.header-other {
  background-color: coral;
}

.portfolio-card-title {

  font-size: 30px;
  font-weight: 700;
  line-height: 1.1;
  /*text-transform: uppercase;*/
  margin-bottom: 10px;
  text-decoration: underline
}
.portfolio-card-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  /*text-transform: uppercase;*/
  /*margin-bottom: 50px;*/
}

.portfolio-col {
  padding-top: 10px;
}
