@font-face {
  font-family: 'Montserrat';
  src: url("assets/fonts/Montserrat-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("assets/fonts/Montserrat-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("assets/fonts/Montserrat-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

html, body #root {
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', 'Helvetica', monospace;
}

/*body {*/
/*  margin: 0;*/
/*  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif !important;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
