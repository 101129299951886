html, body {
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', 'Helvetica', monospace;
}
.cover {
  height: 100%;
  width: 100%;
  /*background-image: linear-gradient(45deg, #00aeef 0%, #096fb9 100%);*/
  overflow: hidden;
  position: relative;
  background-color: #844D36;
}
.wrapper {
  min-height: 100vh;
  min-width: 100vw;
  margin-top: 0;
  position: relative;
}
.main-section {
  top: 150px;
  width: 100%;
  padding: 150px 0 0;
}
.hero-section {
  perspective: 1000px;
  color: #AAA0A0;
  width: 400px;
  position: relative;
}
.hero-section h2 {
  color: #844D36;
  font-weight: bold;
  margin-bottom: 10px;
}
.hero-section-content {
  position: absolute;
  bottom: 20px;
  width: 360px;
  left: 6%;
  z-index: 1;
}
.hero-section-content-intro {
  font-size: 21px;
  /*color: #844D36;*/
  color: black;
}
.image {
  width: auto;
  max-width: 100%;
}
.hero-welcome-text > h1 {
  color: #AAA0A0;
  text-transform: uppercase;
  font-size: 27px;
  margin-top: 80px;
}
.hero-welcome-bio {
  margin-top: 20px;
  color: #AAA0A0;
}
.hero-welcome-bio .fa-stack {
  font-size: 28px;
}
.hero-welcome-bio .list-inline-item > a {
  color: #373737;
}
.hero-welcome-wrapper {
  justify-content: flex-end;
}
@media (max-width: 991px) {
  .hero-section {
    width: 341px;
  }
  .hero-section-content {
    width: 341px;
  }
  .hero-welcome-text > h1 {
    margin-top: 0;
  }
}
.background-image {
  position: absolute;
  width: 100%;
  height: 100%;
}
.shadow-custom {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  box-shadow: -17px 13px 41px #474853;
  border: 2px solid #8E8268;
}
.shadow-custom .shadow-inner {
  width: 100%;
  height: 100%;
  box-shadow: -17px 13px 13px #474853;
}
.shadow-custom-2 {
  border: 2px solid #f37d49;
  box-shadow: -17px 13px 41px rgba(13, 78, 158, 0.3);
}

.self-typed {
  font-size: 31px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #86B3D1;
}
