.absolute {
  position: absolute;
}

// HEADER
.port-navbar.port-default {
  width: 100%;
  z-index: 15;
  padding: 40px;
  background-color: #474853;

  .port-navbar {
    &-brand {
      color: #86B3D1;
      font-size: 36px;
      //text-transform: uppercase;
      letter-spacing: 1px;
      //font-weight: bold;

    }

    &-item {
      color: #AAA0A0;
      margin-left: 10px;
      margin-right: 10px;
      font-size: 24px;
      margin-top: 1rem;
    }


    &-link {
      color: #AAA0A0;
      //font-weight: bold;
      letter-spacing: 0.8px;
      font-size: 24px;
      //text-transform: uppercase;

      &.active {
      color: #dba919;
      }

      &:hover, &:focus {
        color: #dba919;
      }
    }
  }
}
